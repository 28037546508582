@media (min-width: 1199px) {
  * {
    body {
      #long-menu {
        .MuiPaper-root.MuiMenu-paper {
          left: auto !important;
          right: 110px;
          -ms-transform: translate(0, -19px) !important;
          -webkit-transform: translate(0, -19px) !important;
          -moz-transform: translate(0, -19px) !important;
          -o-transform: translate(0, -19px) !important;
          transform: translate(0px, -19px) !important;
        }
      }
    }
  }
}
@media (max-width: 1640px) {
  * body {
    .list-style {
      .select__control {
        min-width: 200px;
      }
    }
  }
}
@media (max-width: 1540px) {
  * {
    body {
      // .list-style {
      //   li {
      //     margin: 0 0px 0 10px;
      //     .select__control {
      //       min-width: 160px;
      //     }
      //   }
      // }
      .date-range-picker {
        .DateRangePickerInput__withBorder {
          min-width: 290px;
          .DateInput {
            width: auto;
          }
        }
        .DateRangePickerInput_calendarIcon {
          padding: 10px 0px 10px 10px;
          margin: 0;
        }
      }
      .dashboard-container {
        .dashboard-filters {
          .autoselect-date-filters {
            .date-range-picker {
              .DateRangePickerInput__withBorder {
                min-width: 290px;
                max-width: 290px;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1440px) {
  * {
    body {
      // .full-w {
      //   &.custom-width {
      //     .logo-wrapper {
      //       .logo {
      //         margin: 0 auto 20px;
      //       }
      //     }
      //   }
      // }

      .top-header {
        .header-inner {
          padding: 10px 20px;
        }
      }

      .date-range-picker {
        .DateRangePickerInput__withBorder {
          min-width: 240px;
          max-width: 240px;
        }
        .DateRangePickerInput_calendarIcon {
          padding: 10px 0px 10px 4px;
          margin: 0;
        }
      }
      .dashboard-container {
        .dashboard-filters {
          .autoselect-date-filters {
            .date-range-picker {
              .DateRangePickerInput__withBorder {
                min-width: 240px;
                max-width: 240px;
              }
            }
          }
        }
      }
      .date-range-picker {
        .date-picker-icon {
          font-size: 18px;
        }
      }
      .location-container {
        .location-head {
          .heading {
            h2 {
              font-size: 22px;
            }
          }
        }
      }
    //   .button-btn {
    //     min-width: 160px;
    //   }
     }
  }
}
@media (max-width: 1380px) {
  // .tabs-custom-btn {
  //   .MuiTabs-flexContainer {
  //     button {
  //       min-width: 160px;
  //       margin: 0 10px;
  //     }
  //     display: flex;
  //     padding-left: 0;
  //   }
  // }
  .d-flex-custom {
    .drop-down-custom {
      .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon {
        .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
          padding-right: 30px;
        }
      }
    }
  }
  .download-csv svg {
    width: 35px;
    height: 35px;
    padding: 8px;
}
}

@media (max-width: 1279px) {
  * {
    body {
      .date-range-picker {
        .DateRangePickerInput__withBorder {
          min-width: 220px;
        }
        .DateRangePickerInput_calendarIcon {
          padding: 10px 0px 10px 4px;
          margin: 0;
        }
      }
      .dashboard-container {
        .dashboard-filters {
          .autoselect-date-filters {
            .date-range-picker {
              .DateRangePickerInput__withBorder {
                min-width: 220px;
                max-width: 220px;
              }
            }
          }
        }
      }

      .dashboard-filters {
        padding: 0px 14px 10px;
      }

      .date-range-picker > div {
        padding: 0;
      }
      .list-style {
        li {
          .select__control {
            min-width: 140px;
            max-width: 140px;
          }
        }
      }
      .dashboard-container {
        .revenue-card-box {
          .revenue-card-container {
            padding: 10px;
            .title {
              font-size: 12px;
            }
            .card-dashboard {
              font-size: 14px;
            }
            .red {
              svg {
                font-size: 18px;
              }
            }
          }
        }
        .dashboard-filters {
          .type-container {
            .location-input-field {
              .display-selection {
                font-size: 16px;
              }
            }
          }
          .display-md-screen1 {
            margin-left: 0 !important;
          }
        }
      }
    }
    .autoselect-date-filters {
      padding-right: 0;
    }
  }
}



@media (max-width: 959px) {
  * body {
    .cus-head-logo img.logo-icon {
      height: 36px;
    }
    header {
      background:var(--color-white) !important;
      color: #000;
      box-shadow: none !important;
      border-bottom: 1px solid #e7eaed;

      .MuiToolbar-regular {
        padding: 14px 20px;
        justify-content: space-between;
        align-items: center;

        button {
          padding: 0;
          margin-left: 0;
          color: #000;
        }

        h6 {
          color: #000;
        }

        .setting-option {
          img {
            width: 20px;
          }
        }
      }
    }

    .custom-menu > div {
      justify-content: normal;
    }

    .cus-head-logo {
      box-shadow: none;
      padding: 0px 20px;
      z-index: auto;
    }

    .custom-menu {
      .sidebar-menus {
        padding: 0px 0px 0;

        .sidepanel-item {
          padding: 20px 60px 20px 15px;
        }
      }
    }

    main {
      overflow: hidden;
      .title-heading {
        font-size: 20px;
        margin: 0 auto 16px;
        text-align: center;
      }
    }

    .MuiToolbar-regular {
      .MuiTablePagination-toolbar {
        padding: 15px 20px;
      }
    }

    .MuiIconButton-root.Mui-disabled {
      padding: 9px 10px;
    }
    .dashboard-container {
      .dashboard-filters {
        .display-md-screen1 {
          margin: 0 0 20px !important;
        }
        .type-container {
          .location-input-field {
            display: flex;
            align-items: center;
            height: 48px;
            border: 1px solid #cccccc;
            padding: 12px;
            border-radius: 4px;
            background: #fff;
          }
        }
      }
    }
  }

  .setting-option.user-name-email-mobile {
    .bottom-email-sec {
      display: flex;

      img {
        width: 34px;
      }

      .option-dropdown {
        color: #000;
        margin-left: 9px;
        display: block;
        text-align: right;

        .logout-icon {
          display: block;
          color: #c29776;
        }
      }
    }
  }

  .location-container {
    .location-head {
      .search-add {
        display: flex;
        margin-top: 20px;
      }
    }
  }

  * body {
    .transaction-page {
      margin-top: 20px;
    }
  }
}
@media (max-width: 959px) and (min-width: 768px) {
  * {
    body {
      .list-style {
        li {
          .select__control {
            min-width: 232px;
            max-width: 232px;
          }
        }
      }
      .transaction-page {
        .list-style {
          li {
            .select__control {
              min-width: 154px;
              max-width: 154px;
            }
          }
        }
      }
    }
  }
}


@media (max-width: 600px) {
  * body {
    .cus-dialog {
      .dialog-btns {
        button {
          min-height: 40px;
          padding: 0 !important;
          min-width: 120px;
          margin: 0 4px 0 !important;
        }
      }
    }
  }

  .card-design {
    .MuiTablePagination-root {
      .MuiToolbar-root {
        display: inline-block;
        width: 100%;
        text-align: center;
      }

      div,
      p {
        display: inline-block;
        width: auto;
      }

      div {
        &:last-child {
          display: block;
          text-align: center;
          margin: 0;
        }
      }

      .MuiTablePagination-input {
        position: relative;
        top: 12px;
        margin: 0 10px 0 0;

        svg {
          height: 20px;
        }
      }
    }
  }

  .table-section {
    .MuiTablePagination-root {
      .MuiToolbar-root {
        display: inline-block;
        width: 100%;
        text-align: center;
      }

      div,
      p {
        display: inline-block;
        width: auto;
      }

      div {
        &:last-child {
          display: block;
          text-align: center;
          margin: 0;
        }
      }

      .MuiTablePagination-input {
        position: relative;
        top: 12px;
        margin: 0 10px 0 0;

        svg {
          height: 20px;
        }
      }
    }
  }



  .tabs-custom-dashboard {
    margin-top: 12px;

    div {
      padding: 0;
    }
  }

  .heading-chart {
    .view-all-button {
      padding: 5px 10px;
      font-size: 11px;

      svg {
        margin-right: 4px;
        font-size: 18px;
      }
    }
  }

  .add-location-form-container {
    .add-partner-item {
      &.login-addpartner {
        .button-btn {
          &.btn-custom-border {
            margin-top: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 530px) {
  * {
    body {
      .DateRangePicker {
        position: relative;
        display: inline-block;
        width: 100%;
      }

      .current-div {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 480px) {
  * body {
    .custom-modal {
      margin: 15px !important;
    }

    #no-more-tables td:before {
      font-size: 12px;
    }

    .current-div p {
      font-size: 14px;
    }

    .button-btn {
      padding: 9px 16px;
      min-height: 38px;
    }

    .common-add-component {
      .footer {
        .buttons-div button {
          min-width: 115px;
          margin: 0 5px 0;
        }
      }
    }

    .full-w.custom-width {
      padding: 30px;
    }
  }

  .location-container {
    .location-head {
      .search-add {
        flex-direction: column;

        .custom-search-field {
          margin-right: 0;
          margin-bottom: 20px;
        }
      }
    }
  }
}

@media (max-width: 450px) {
  * body {
    .current-div {
      padding: 0 2px;

      p {
        font-size: 14px;
        text-align: center !important;
      }

      &:nth-child(2) {
        padding: 0 9px;
      }
    }
  }

  .add-location-form-container {
    padding: 16px;
  }

  * {
    body {
      .button-btn.btn-custom-border.add-partner-btn {
        padding: 0 4px;
        min-width: auto;
        min-height: 40px;
        font-weight: 400;
        border-radius: 6px;
        border-width: 1px;
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 390px) {
  .add-location-form-container {
    padding: 16px;
  }

  * {
    body {
      .button-btn {
        &.btn-custom-border {
          &.add-partner-btn {
            padding: 0 4px;
            min-width: auto;
            border-width: 1px;
            font-size: 12px;
          }

          &.get-zipcodes-btn {
            width: 100%;
            min-width: 100%;
            max-width: 100%;
          }
        }
      }
    }
  }
}

@media (min-width: 280px) and (max-width: 767px) {
  .d-xs-none {
    display: none !important;
  }
}

@media (max-width: 520px) {
  form.full-w {
    max-width: 380px;
    min-width: 380px;
    margin: 0 auto;
    padding: 0;
  }
}
@media (max-width: 410px) {
  form.full-w {
    max-width: 310px;
    min-width: 310px;
    margin: 0 auto;
    padding: 0;
  }
}
@media (max-width: 340px) {
  form.full-w {
    max-width: 290px;
    min-width: 290px;
    margin: 0 auto;
    padding: 0;
  }
}
